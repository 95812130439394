<template>
  <div
    v-show="showModal"
    class="layer-survey-detail"
  >
    <div class="medical-detail-wrap">
      <div class="layer-utility">
        <button
          class="btn-close"
          @click.prevent="beforeClose"
        >
          close
        </button>
      </div>

      <div class="md-header survey-layer-type">
        <dl>
          <dt style="font-size: 24px;">
            {{ title }}
          </dt>
          <dd>{{ startDate | $dateFormatter }}{{ startDate ? ' ~ ' : '' }}{{ endDate | $dateFormatter }}</dd>
        </dl>
      </div>

      <div class="md-contents">
        <div
          v-if="!isRewardPage"
          class="survey-layer-area"
        >
          <div
            v-dompurify-html="currPage ? currPage.description : ''"
            class="survey-welcome-info"
          />

          <div class="survey-list">
            <template
              v-for="q in elements"
            >
              <div
                v-if="q.isShow"
                :key="`el${q.id}`"
                :ref="`el${q.id}`"
                class="survey-item"
              >
                <dl>
                  <dt>
                    <label
                      v-show="q.isRequired"
                      class="essential-label"
                    >필수</label>
                    <p><span>{{ q.dispSeq }}.</span>{{ q.title }}</p>
                  </dt>
                  <dd v-if="q.description">
                    <div
                      v-dompurify-html="q.description"
                      class="gray-box"
                    />
                  </dd>
                  <dd>
                    <!-- 선택(단일) -->
                    <template v-if="q.type === 1">
                      <ul>
                        <template
                          v-for="ch in q.choices"
                        >
                          <li :key="`ch${ch.id}`">
                            <div class="sur-radio-wrap">
                              <input
                                :id="`ch${ch.id}`"
                                v-model="q.answer"
                                :value="ch.id"
                                :name="`el${q.id}`"
                                type="radio"
                                @change.prevent="changeAnswer()"
                              >
                              <label :for="`ch${ch.id}`">{{ ch.description }}</label>
                            </div>
                          </li>
                          <li
                            v-if="ch.isEtc"
                            :key="`ch-other${ch.id}`"
                            class="mgl28"
                          >
                            <div class="text-wrap">
                              <input
                                v-model="q.answerEtcText"
                                type="text"
                                :disabled="q.answer !== ch.id"
                              >
                            </div>
                          </li>
                        </template>
                      </ul>
                    </template>

                    <!-- 선택(복수) -->
                    <template v-if="q.type === 2">
                      <ul>
                        <template
                          v-for="ch in q.choices"
                        >
                          <li :key="`ch${ch.id}`">
                            <div class="sur-checkbox-wrap">
                              <input
                                :id="`ch${ch.id}`"
                                v-model="q.answer"
                                :value="ch.id"
                                type="checkbox"
                                @change.prevent="changeAnswer()"
                              >
                              <label :for="`ch${ch.id}`">{{ ch.description }}</label>
                            </div>
                          </li>
                          <li
                            v-if="ch.isEtc"
                            :key="`ch-other${ch.id}`"
                            class="mgl28"
                          >
                            <div class="text-wrap">
                              <input
                                v-model="q.answerEtcText"
                                type="text"
                                :disabled="q.answer.indexOf(ch.id) === -1"
                              >
                            </div>
                          </li>
                        </template>
                      </ul>
                    </template>

                    <!-- 텍스트(한줄) -->
                    <template v-if="q.type === 3">
                      <div class="text-wrap">
                        <input
                          v-model="q.answer"
                          @input="filteredValue(q)"
                          @change.prevent="changeAnswer()"
                        >
                      </div>
                    </template>

                    <!-- 텍스트(여러줄) -->
                    <template v-if="q.type === 4">
                      <div class="textarea-wrap">
                        <textarea
                          v-model="q.answer"
                          :rows="q.rows"
                          @input="filteredValue(q)"
                          @change.prevent="changeAnswer()"
                        />
                      </div>
                    </template>

                    <!-- 순위형 -->
                    <template v-if="q.type === 5">
                      <ul>
                        <template
                          v-for="ch in q.choices"
                        >
                          <li :key="`ch${ch.id}`">
                            <button
                              class="btnRanking"
                              :class="{ active: ch.rank }"
                              @click.prevent="clickRank(q, ch.id)"
                            >
                              <span class="ranking"><em>{{ ch.rank }}</em></span>{{ ch.description }}
                            </button>
                          </li>
                          <li
                            v-if="ch.isEtc"
                            :key="`ch-other${ch.id}`"
                            class="mgl28"
                          >
                            <div class="text-wrap">
                              <input
                                v-model="q.answerEtcText"
                                type="text"
                                :disabled="q.answer.indexOf(ch.id) === -1"
                              >
                            </div>
                          </li>
                        </template>
                      </ul>
                    </template>

                    <!-- 자동제출 -->
                    <template v-if="q.type === 10">
                      &nbsp;
                    </template>
                  </dd>
                </dl>
              </div>
              <div
                v-show="getErrorMessage(q.id)"
                :key="`invalid${q.id}`"
                class="message-box"
              >
                <p class="essential-message">
                  {{ getErrorMessage(q.id) }}
                </p>
              </div>
            </template>
          </div>

          <div
            v-if="elements && elements.length"
            class="btn-wrap"
          >
            <button
              :class="currPageIdx > 0 ? 'btn-sendIn' : 'btn-before'"
              @click.prevent="prevPage"
            >
              이전
            </button>
            <button
              v-if="pages.length && currPageIdx < pages.length - 1"
              class="btn-sendIn"
              @click.prevent="nextPage"
            >
              다음
            </button>
            <button
              v-if="pages.length && currPageIdx === pages.length - 1"
              class="btn-sendIn"
              @click.prevent="nextPage"
            >
              제출하기
            </button>
          </div>
        </div>

        <!-- 보상 선택 -->
        <reward-choice
          v-if="isRewardPage"
          :id="id"
          @choice="rewardChoiceResult"
        />

      </div>
    </div>

    <alert-dialog :options="alertProps" />
    <confirm-dialog :options="confirmProps" />

    <!-- 보상완료 알림 전용 다이얼로그 -->
    <reward-completed-dialog :options="rewardCompletedDialogProps" />
  </div>
</template>

<script>
import axios from '@axios'
import { mapGetters } from 'vuex'
import { setError, setException } from '@/common/error/log'
import { setStatistics } from '@/common/statistics/service'
import { changeUserPoint } from '@/auth/utils'
import { errorFormatter, numberFormatter } from '@/libs/utils/filters'
import RewardChoice from './components/RewardChoice.vue'
import RewardCompletedDialog from './components/RewardCompletedDialog.vue'

export default {
  components: {
    RewardChoice,
    RewardCompletedDialog,
  },

  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },

    entry: {
      type: Number,
      required: false,
      default: null,
    },

    showModal: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      title: null,
      startDate: null,
      endDate: null,
      pages: [],
      elements: [],
      currPage: null,
      currPageIdx: null,
      errorMessages: {},
      isRewardPage: false,
      isProcessing: false,

      // 문항 1개라도 입력값이 변경된 경우 true
      modified: false,

      rewardCompletedDialogProps: {
        show: false,
        message: '',
        bannerPath: null,
        bannerContentId: null,
        submitCallback: null,
      },
    }
  },

  computed: {
    ...mapGetters({
      medicalDept: 'infoData/getMedicalDept',
      shaYoyangNum: 'infoData/getShaYoyangNum',
      shaEnLicenseNum: 'infoData/getShaEnLicenseNum',
      hAddress: 'infoData/getHAddress',
    }),
  },

  watch: {
    id(to) {
      if (to) {
        this.fetchSurveyPaper(to)
      }
    },
  },

  methods: {
    showRewardCompletedDialog(message, bannerPath, bannerContentId, callback) {
      this.rewardCompletedDialogProps = {
        show: true,
        message,
        bannerPath,
        bannerContentId,
        submitCallback: callback || null,
      }
      return this.rewardCompletedDialogProps
    },

    prevPage() {
      if (!this.currPageIdx) {
        return
      }
      this.loadPage((this.currPageIdx - 1))
    },

    async nextPage() {
      // 설문 참여대상 여부 체크(*비대상 구분 문항 존재 시)
      const targetResult = this.checkNonTarget()
      if (targetResult.nonTarget) {
        // 설문 대상이 아닌 경우에도 답변 내역 저장
        await this.save(true)

        const defaultNonTargetMessage = '참여해 주셔서 감사합니다.\n\n'
        + '본 조사에서는 조사 결과의 활용성을 높이기 위해,\n'
        + '응답 대상자의 조건을 제한하고 있습니다.\n\n'
        + '선생님께서는 본 설문조사의 대상자가 아닙니다.\n'
        + '부득이하게 조사를 종료 하오니 너른 양해 부탁 드립니다.\n'
        + '감사합니다.'
        this.showAlertDialog((targetResult.finishMessage ? targetResult.finishMessage : defaultNonTargetMessage), async () => {
          await this.saveNonTarget()

          this.close(true)
        })
        return
      }

      // 페이지 이동(또는 제출 전) Validation
      const inValidElements = this.validate()
      if (inValidElements.length) {
        const firstErrorRef = `el${inValidElements[0]}`
        this.$refs[firstErrorRef][0].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        })
        return
      }
      // Validation end.

      if (this.currPageIdx !== null && (this.currPageIdx + 1) === this.pages.length) {
        // 마지막 페이지인 경우
        if (this.modified) {
          // 답변 데이터 저장
          const result = await this.save(false, true)
          if (!result) return
        }
        this.showConfirmDialog('작성을 완료하셨습니다.\n제출하시겠습니까?', confirm => {
          if (confirm) {
            this.submit()
          }
        })
      } else {
        let loadPageIdx = null
        if (this.currPage === null) {
          loadPageIdx = 0
        } else {
          // 답변 데이터 저장
          if (this.modified) {
            const result = await this.save(false)
            if (!result) return
          }
          loadPageIdx = this.currPageIdx + 1
        }

        this.loadPage(loadPageIdx)
      }
    },

    loadPage(pageIdx) {
      // 페이지 로드
      this.currPageIdx = pageIdx
      this.currPage = this.pages[pageIdx]
      this.fetchPage(this.currPage.id)
    },

    loadRewardPage() {
      this.isRewardPage = true
    },

    getErrorMessage(elementId) {
      return Object.keys(this.errorMessages).length === 0 ? null : (this.errorMessages[elementId] || null)
    },

    checkNonTarget() {
      const result = { nonTarget: false, finishMessage: null }
      const { elements } = this

      for (let i = 0; i < elements.length; i += 1) {
        const el = this.elements[i]

        if (el.answer && el.type === 1) {
          // 선택(단일) 문항에서 isFinish(비대상 종료) 답변으로 체크한 경우 true
          result.nonTarget = el.choices.some(x => x.id === el.answer && x.isFinish)
        }

        if (result.nonTarget) {
          result.finishMessage = el.finishMessage
          break
        }
      } // for i end.

      return result
    },

    // Validation
    validate() {
      const inValidElements = []
      const { elements } = this

      for (let i = 0; i < elements.length; i += 1) {
        let isValid = true
        const el = elements[i]
        if (el.isShow === false) {
          // 숨겨진 문항은 Validation 체크 제외
          this.$delete(this.errorMessages, el.id)
          continue
        }
        // 필수 입력 체크
        if (el.isRequired) {
          if (el.type === 10) {
            // 자동제출 유형
            if (!el.autoAnswers || el.autoAnswers.length === 0) {
              isValid = false
            } else {
              let existResults = 0
              for (let j = 0; j < el.autoAnswers.length; j += 1) {
                if (el.autoAnswers[j].autoResult) {
                  existResults += 1
                }
              }
              isValid = (existResults > 0)
            }
          } else if (!el.answer || el.answer.length === 0) {
            // 그 외 유형
            isValid = false
          }

          if (isValid) {
            this.$delete(this.errorMessages, el.id)
          } else {
            inValidElements.push(el.id)
            this.$set(this.errorMessages, el.id, '답변 필수 문항입니다.')
          }
        }
        // 기타텍스트 입력 여부(선택[단일,복수], 순위형에 해당)
        if (isValid && (el.type === 1 || el.type === 2 || el.type === 5)) {
          let etcId = 0
          for (let j = 0; j < el.choices.length; j += 1) {
            if (el.choices[j].isEtc) {
              etcId = el.choices[j].id
            }
          }
          if (etcId !== 0) {
            if (el.type === 1 && el.answer === etcId && !el.answerEtcText) {
              isValid = false
            } else if ((el.type === 2 || el.type === 5) && el.answer.indexOf(etcId) !== -1 && !el.answerEtcText) {
              isValid = false
            }

            if (isValid) {
              this.$delete(this.errorMessages, el.id)
            } else {
              inValidElements.push(el.id)
              this.$set(this.errorMessages, el.id, '기타 내용을 입력하여 주시기 바랍니다.')
            }
          }
        }
        // 텍스트 답변 형식 체크
        // 전체(null or 0), 문자만(1), 숫자만(2)
        if (isValid && (el.type === 3 || el.type === 4)) {
          if (el.textType) {
            if (el.textType === 1) {
              if (!new RegExp('^([^0-9]*)$').test(el.answer)) {
                isValid = false
              }
            } else if (el.textType === 2) {
              if (!new RegExp('^[0-9]*$').test(el.answer)) {
                isValid = false
              }
            }

            if (isValid) {
              this.$delete(this.errorMessages, el.id)
            } else {
              inValidElements.push(el.id)
              this.$set(this.errorMessages, el.id, `${el.textType === 1 ? '문자' : '숫자'}만 입력 가능합니다.`)
            }
          }
        }
        // 최소 체크 개수 충족여부
        if (isValid && el.minCheck !== null) {
          if (el.minCheck > el.answer.length) {
            isValid = false
          }
          if (isValid) {
            this.$delete(this.errorMessages, el.id)
          } else {
            inValidElements.push(el.id)

            let inValidMessage = ''
            if (el.type === 3 || el.type === 4) {
              // 텍스트 유형
              inValidMessage = `최소 ${el.minCheck}글자 이상 입력하여 주시기 바랍니다.`
            } else {
              // 체크, 순위 유형
              inValidMessage = `최소 ${el.minCheck}개 이상 체크하여 주시기 바랍니다.`
            }
            this.$set(this.errorMessages, el.id, inValidMessage)
          }
        }
        // 최대 체크 개수 충족여부
        if (isValid && el.maxCheck !== null) {
          if (el.maxCheck < el.answer.length) {
            isValid = false
          }
          if (isValid) {
            this.$delete(this.errorMessages, el.id)
          } else {
            inValidElements.push(el.id)

            let inValidMessage = ''
            if (el.type === 3 || el.type === 4) {
              // 텍스트 유형
              inValidMessage = `최대 ${el.maxCheck}글자 미만 입력하여 주시기 바랍니다.`
            } else {
              // 체크, 순위 유형
              inValidMessage = `최대 ${el.maxCheck}개 이하로 체크하여 주시기 바랍니다.`
            }
            this.$set(this.errorMessages, el.id, inValidMessage)
          }
        }
      } // for i end.
      return inValidElements
    },

    // 현재 페이지에 표시될 문항의 연결 여부를 확인하여 재 표시
    showElements() {
      const { elements } = this

      for (let i = 0; i < elements.length; i += 1) {
        const el = elements[i]

        const isShow = this.isShowElement(el.seq)

        this.$set(el, 'isShow', isShow) // 문항 표시 설정
        if (isShow === false) {
          // 숨겨진 문항은 답변 초기화
          if (el.type === 2 || el.type === 5) el.answer = []
          else el.answer = null

          if (el.answerEtcText) {
            el.answerEtcText = null
          }

          // 숨겨진 문항은 Validation 체크 제외
          this.$delete(this.errorMessages, el.id)
        }
      } // for i end

      // 문항번호 재 지정
      this.resetElementSequence()
    },

    // 해당 문항 표시 여부 반환
    isShowElement(elementSeq) {
      const { elements } = this

      let isShow = null
      for (let i = 0; i < elements.length; i += 1) {
        if (isShow === true) break

        const el = elements[i]

        if (el.type === 1 || el.type === 2 || el.type === 5) {
          // 선택형 문항(라디오, 체크박스, 순위형)에 대한 체크
          const { choices } = el
          for (let j = 0; j < choices.length; j += 1) {
            if (isShow === true) break

            const ch = choices[j]

            // showElement에 체크 문항이 포함되어 있는 경우 답변 여부 체크
            if (ch.showElementList && ch.showElementList.length && ch.showElementList.indexOf(elementSeq) !== -1) {
              if (el.answer && el.answer === ch.id) {
                isShow = true
              } else if (el.answer && typeof el.answer === 'object' && el.answer.indexOf(ch.id) !== -1) {
                isShow = true
              } else {
                isShow = false
              }
            }
          } // for j end
        } else if (el.type === 3 || el.type === 4) {
          // 텍스트, 코멘트 유형에 대한 체크

          // showElement에 체크 문항이 포함되어 있는 경우 답변 여부 체크
          if (el.inputShowElementList && el.inputShowElementList.length && el.inputShowElementList.indexOf(elementSeq) !== -1) {
            isShow = !!el.answer // 텍스트 유형은 한 글자라도 입력되어 있으면 true
          }
        }
      } // for i end

      return isShow === null ? true : isShow
    },

    // 문항 표시 변경 이후 번호 재 지정
    resetElementSequence() {
      let sequence = 1
      const { elements } = this
      for (let i = 0; i < elements.length; i += 1) {
        if (elements[i].isShow !== false) {
          elements[i].dispSeq = sequence
          sequence += 1
        }
      }
    },

    clickRank(el, choiceId) {
      const { answer } = el

      if (choiceId) {
        const existIdx = answer.indexOf(choiceId)
        if (existIdx !== -1) {
          // 선택목록에 들어 있으면
          answer.splice(existIdx, 1)
        } else {
          answer.push(choiceId)
        }
      }

      this.changeAnswer()
      this.refreshRank(el)
    },

    refreshRank(el) {
      const { answer } = el
      const { choices } = el

      // 선택목록 순서대로 Rank 재 할당
      for (let i = 0; i < choices.length; i += 1) {
        this.$set(choices[i], 'rank', null)
      }
      for (let i = 0; i < answer.length; i += 1) {
        for (let j = 0; j < choices.length; j += 1) {
          if (answer[i] === choices[j].id) {
            this.$set(choices[j], 'rank', (i + 1))
          }
        }
      }
    },

    changeAnswer() {
      this.modified = true

      this.showElements()
    },

    filteredValue(element) {
      const el = element
      let inputValue = el.answer

      if (el.maxCheck && inputValue.length > el.maxCheck) {
        inputValue = inputValue.slice(0, el.maxCheck)
      }
      if (el.textType === 1) {
        inputValue = inputValue.replace(/[0-9]/, '')
      }
      if (el.textType === 2) {
        inputValue = inputValue.replace(/[^0-9]/g, '')
      }

      el.answer = inputValue
    },

    fetchSurveyPaper(id) {
      axios.get(`/fu/survey/paper/${id}`, { params: { userHash: this.shaEnLicenseNum } })
        .then(rs => {
          this.title = rs.data.title
          this.startDate = rs.data.startDate
          this.endDate = rs.data.endDate
          this.pages = rs.data.pages

          if (this.pages.length === 0) {
            this.showAlertDialog('설문 정보가 잘못 입력되었습니다.\n관리자에게 문의해 주세요.')
            return
          }

          const { continuePageIdx, continueStatus } = rs.data
          if (continueStatus !== null && continueStatus === 5) { // 5: 제출완료
            this.loadRewardPage()
          } else if (continuePageIdx !== null) {
            this.loadPage(continuePageIdx)
          } else {
            this.loadPage(0)
          }
        })
        .catch(err => {
          this.showAlertDialog(errorFormatter(err, '설문조사를 불러오는데 실패하였습니다.\n잠시 후 다시 시도해 주세요.'))
        })
    },

    fetchPage(pageId) {
      axios.get(`/fu/survey/elements/${this.id}/${pageId}`, { params: { userHash: this.shaEnLicenseNum } })
        .then(rs => {
          const { elements } = rs.data
          const { oldAnswers } = rs.data

          for (let i = 0; i < elements.length; i += 1) {
            const el = elements[i]

            // 이전 제출 답변 존재 시
            if (oldAnswers && oldAnswers.length) {
              let oldAnswer = null
              if (el.type === 2 || el.type === 5) {
                // 선택[복수]형(2), 순위형(5) 설문은 배열 값으로 초기화
                oldAnswer = []
              }
              for (let j = 0; j < oldAnswers.length; j += 1) {
                const old = oldAnswers[j]

                if (el.id === old.surveyElementId) {
                  if (el.type === 1) {
                    el.answer = old.answer ? Number(old.answer) : null
                  } else if (old.answer && (el.type === 2 || el.type === 5)) {
                    oldAnswer.push(Number(old.answer))
                  } else if (old.answer && (el.type === 3 || el.type === 4)) {
                    el.answer = old.answer
                  } // 자동제출(10)은 과거 제출 답변을 채우지 않음

                  // 기타텍스트 존재 시 삽입
                  if (old.answerEtcText) {
                    el.answerEtcText = old.answerEtcText
                  }
                }
              }
              if (oldAnswer) {
                // 선택[복수]형(2), 순위형(5) 기존 답변 값 삽입
                el.answer = oldAnswer
                if (el.type === 5) {
                  // 순위 표시 새로고침
                  this.refreshRank(el)
                }
              }
            }
            // 이전 제출 답변 바인딩 end

            if (!el.answer) {
              if (el.type === 2 || el.type === 5) {
              // 선택[복수]형(2), 순위형(5) 설문은 배열 값으로 초기화
                el.answer = []
              } else {
                el.answer = null
              }
            }
            el.isShow = true // 보여주기 기본 값 true
            el.dispSeq = (i + 1) // 문항표시번호 기본 값
          }

          this.elements = elements
          this.showElements()

          // 2페이지부터 스크롤 위치는 상단으로 변경
          if (this.currPageIdx && elements.length) {
            this.$nextTick(() => {
              const firstElement = `el${elements[0].id}`
              this.$refs[firstElement][0].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
              })
            })
          }
        })
        .catch(() => {
          this.showAlertDialog('페이지 문항을 불러오는데 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
        })
    },

    async saveNonTarget() {
      const result = await axios.post(`/fu/survey/non-target/${this.id}`, {
        entryType: this.entry,
        medicalDept: this.medicalDept,
        dupCheckId: this.shaYoyangNum,
        userHash: this.shaEnLicenseNum,
      })
        .then(() => true)
        .catch(() => false)

      return result
    },

    async save(isClose, isLastPage = false) {
      const continuePageIdx = (isClose || isLastPage) ? this.currPageIdx : this.currPageIdx + 1
      const answers = []

      for (let i = 0; i < this.elements.length; i += 1) {
        const el = this.elements[i]

        if (el.answer && el.type === 1) {
          // 1:선택(단일)
          answers.push({
            surveyElementId: el.id,
            seq: 1,
            answer: el.answer,
            answerEtcText: el.choices.some(x => x.id === el.answer && x.isEtc) ? el.answerEtcText : null,
          })
        } else if (el.answer && el.answer.length && (el.type === 2 || el.type === 5)) {
          // 2:선택(복수), 5:순위형
          const choiceAnswers = el.answer.map((choiceId, idx) => ({
            surveyElementId: el.id,
            seq: (idx + 1),
            answer: choiceId,
            answerEtcText: el.choices.some(x => x.id === choiceId && x.isEtc) ? el.answerEtcText : null,
          }))
          answers.push(...choiceAnswers)
        } else if (el.answer && el.answer.length && (el.type === 3 || el.type === 4)) {
          // 3:텍스트(한줄), 4:텍스트(여러줄)
          answers.push({
            surveyElementId: el.id,
            seq: 1,
            answer: el.answer,
          })
        } else if (el.answer && el.answer.length && el.type === 10) {
          // 10:자동제출
          const autoAnswers = el.answer.map((auto, idx) => ({
            surveyElementId: el.id,
            seq: (idx + 1),
            answer: auto.autoResult,
            surveyElementAutoId: auto.autoId,
          }))
          answers.push(...autoAnswers)
        }
      }

      const result = await axios.post(`/fu/survey/answer/${this.id}/${this.currPage.id}`, {
        continuePageIdx,
        entryType: this.entry,
        medicalDept: this.medicalDept,
        answers,
        dupCheckId: this.shaYoyangNum,
        userHash: this.shaEnLicenseNum,
      })
        .then(() => true)
        .catch(err => {
          // 창을 닫으면서 자동 임시저장 하는 경우에는 오류 시 메시지를 띄우지 않음
          if (!isClose) {
            this.showAlertDialog(errorFormatter(err, '작성하신 내용을 저장하는 과정에서 오류가 발생하였습니다.'))
          }
          return false
        })

      return result
    },

    submit() {
      if (!this.isProcessing) {
        this.isProcessing = true

        axios.post(`/fu/survey/submit/${this.id}`, {
          entryType: this.entry,
          medicalDept: this.medicalDept,
          dupCheckId: this.shaYoyangNum,
          userHash: this.shaEnLicenseNum,
        })
          .then(() => {
            // 설문조사 제출 통계
            setStatistics(159, this.id)

            // 보상 페이지로 변경
            this.loadRewardPage()

            this.isProcessing = false
          })
          .catch(err => {
            this.isProcessing = false

            this.showAlertDialog(errorFormatter(err, '설문 제출 과정에서 오류가 발생하였습니다.\n재시도하여도 문제가 계속될 경우 관리자에게 문의하여 주시기 바랍니다.'))
            setException(err, 'SurveyView.vue')
          })
      }
    },

    rewardChoiceResult(result) {
      if (result.type === 1 && result.isJoin === false) this.moveLoginPage()
      else if (result.type === 1) this.rewardPoint()
      else if (result.type === 2 && result.privacy) this.rewardGift(result.privacy)
      else setError('보상 선택에서 알 수 없는 유형 반환', 'SurveyView.vue')
    },

    moveLoginPage() {
      this.$router.push({
        name: 'login-main',
        params: {
          id: this.id, surveyType: 1, entry: this.entry, backContinue: true
        },
        query: { redirect: 'app-survey-main' }
      }).catch(() => {})
    },

    rewardPoint() {
      if (!this.isProcessing) {
        this.isProcessing = true

        axios.post(`/fu/survey/reward-point/${this.id}`, this.shaEnLicenseNum)
          .then(rs => {
            const { rewardPoint, resultUserPoint, insertBanner } = rs.data
            changeUserPoint(resultUserPoint)

            const resultMessage = `설문 보상으로 ${numberFormatter(rewardPoint)} 포인트가\n지급되었습니다.`

            if (insertBanner) {
              // 결과 알림창에 표시할 배너 존재 시
              this.showRewardCompletedDialog(resultMessage, insertBanner.filePath, insertBanner.contentId, () => {
                this.isProcessing = false
                this.close(true)
              })
            } else {
              this.showAlertDialog(resultMessage, () => {
                this.isProcessing = false
                this.close(true)
              })
            }
          })
          .catch(err => {
            this.isProcessing = false

            this.showAlertDialog(errorFormatter(err, '설문 보상 포인트 지급 과정에서 오류가 발생하였습니다.\n관리자에게 문의하여 주시기 바랍니다.'))
            setException(err, 'SurveyView.vue')
          })
      }
    },

    rewardGift(privacy) {
      if (!this.isProcessing) {
        this.isProcessing = true

        axios.post(`/fu/survey/reward-gift/${this.id}`, {
          userHash: this.shaEnLicenseNum,
          userName: privacy.name,
          phoneNumber: privacy.phoneNum,
          medicalDept: this.medicalDept,
          workAddress: this.hAddress,
        })
          .then(rs => {
            const { isGiftSuccess, insertBanner } = rs.data

            if (isGiftSuccess) {
              const resultMessage = privacy.verify ? '회원정보에 등록 된 휴대폰번호로\n기프티콘이 전송되었습니다.' : '입력하신 휴대폰번호로\n기프티콘이 전송되었습니다.'

              if (insertBanner) {
                // 결과 알림창에 표시할 배너 존재 시
                this.showRewardCompletedDialog(resultMessage, insertBanner.filePath, insertBanner.contentId, () => {
                  this.isProcessing = false
                  this.close(true)
                })
              } else {
                this.showAlertDialog(resultMessage, () => {
                  this.isProcessing = false
                  this.close(true)
                })
              }
            } else {
              const failureMessage = privacy.verify
                ? '기프티콘 <b>발송이 지연되어 금일 내 전송</b> 될 예정입니다.<br/>(주말 및 공휴일 제외)'
                : '기프티콘 <b>발송이 지연되어</b><br> 입력하신 휴대폰번호로 <b>금일 내 전송</b> 될 예정입니다.<br>(주말 및 공휴일 제외)'

              this.showAlertDialog(failureMessage, () => {
                this.isProcessing = false
                this.close(true)
              }, { isHtml: true })
            }
          })
          .catch(err => {
            this.isProcessing = false

            this.showAlertDialog(errorFormatter(err, '설문 기프티콘 보상 지급 과정에서 오류가 발생하였습니다.\n관리자에게 문의하여 주시기 바랍니다.'))
            if (err.response.status === 500) setException(err, 'SurveyView.vue')
          })
      }
    },

    beforeClose() {
      if (this.isProcessing) return // 설문제출, 보상진행 중 대기

      if (this.isRewardPage) {
        // 보상 선택 페이지인 경우
        this.showConfirmDialog('보상을 선택하지 않으셨습니다.\n지금 보상을 선택하지 않으면 이후에는 본 설문의 보상을 받으실 수 없습니다.\n보상을 받지 않으시겠습니까?', confirm => {
          if (confirm) return

          // 참여완료 설문으로 처리
          axios.patch(`/fu/survey/skip-continue/${this.id}`, this.shaEnLicenseNum)
            .then(() => this.close(true))
            .catch(() => {
              this.showAlertDialog('보상 선택 거부 처리 과정에서 오류가 발생하였습니다.\n잠시 후 다시 시도해 주세요.')
              this.close(false)
            })
        }, {
          okText: '보상 선택하기',
          cancelText: '보상 받지않기'
        })
      } else {
        // 답변 내역이 존재하는지 체크
        if (this.modified) {
          // 답변 내용 임시저장
          this.save(true)
        }
        this.close(false)
      }
    },

    close(isRefresh) {
      this.title = null
      this.startDate = null
      this.endDate = null
      this.pages = []
      this.elements = []
      this.currPage = null
      this.currPageIdx = null
      this.errorMessages = {}
      this.modified = false
      this.isRewardPage = false

      this.$emit('close', isRefresh)
    },
  },
}
</script>
